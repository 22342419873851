// RGB 转十六进制辅助函数
function RGBToHex(r, g, b) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}

// 将十六进制颜色转换为 RGB
function hexToRGB(hex) {
  return {
    r: parseInt(hex.slice(1, 3), 16),
    g: parseInt(hex.slice(3, 5), 16),
    b: parseInt(hex.slice(5, 7), 16),
  }
}

// 调整 RGB 值
function adjustBrightness(r, g, b, factor) {
  return {
    r: Math.min(255, Math.round(r * factor)),
    g: Math.min(255, Math.round(g * factor)),
    b: Math.min(255, Math.round(b * factor)),
  }
}

export function generateLinkColorsRGBA(hex, factor = 1, opacity = 1) {
  // factor越大,色系越深
  // opacity透明度
  const { r, g, b } = hexToRGB(hex) // 转换十六进制为 RGB
  const adjusted = adjustBrightness(r, g, b, factor) // 调整亮度
  return `rgba(${adjusted.r}, ${adjusted.g}, ${adjusted.b}, ${opacity})` // 生成 rgba 格式
}
