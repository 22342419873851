const {
  VITE_PUBLIC_PATH,
  VITE_API_BASE_URL,
  VITE_API_UPLOAD_URL,
  VITE_API_UPLOAD_SAVE_FILE_NAME_URL,
  VITE_SITE_NAME,
  VITE_APP_ENV,
  VITE_DD_APPKEY,
  VITE_DD_REDIRECT_URL,
  VITE_API_ERP_UPLOAD_URL
} = import.meta.env

type Env = {

  /** 钉钉appkey */
  ddAppKey: string,

  /** 钉钉扫码登录后重定向回的地址 */
  ddRedirectUrl: string,

  /** 公共路径 */
  publicPath: string;

  /** 基础请求路径，已实现代理 */
  baseApiUrl: string;

  /** 文件上传路径 */
  uploadApiUrl: string;

   /** 文件上传路径同步erp */
  erpUploadApiUrl: string;

  /** 文件上传路径(保存文件名) */
  uploadApiSaveFileNameUrl: string;

  /** 项目名称 */
  siteName: string;

  /** 当前程序运行环境 */
  appEnv: 'development' | 'test' | 'production';

  /** 当前是否为开发环境 */
  isDevelopment: boolean;

  /** 当前是否为测试环境 */
  isTest: boolean;

  /** 当前是否为生产环境 */
  isProduction: boolean;

  /* 测试账号 */
  testAccount: string;

  /* 测试密码 */
  testPassword: string;
}

console.log(
  'VITE_APP_ENV', VITE_APP_ENV, VITE_DD_APPKEY,
  VITE_DD_REDIRECT_URL
)

export const useEnv: Env = {
  publicPath: VITE_PUBLIC_PATH as string,
  baseApiUrl: VITE_API_BASE_URL as string,
  uploadApiUrl: VITE_API_UPLOAD_URL as string,
  erpUploadApiUrl: VITE_API_ERP_UPLOAD_URL as string,
  uploadApiSaveFileNameUrl: VITE_API_UPLOAD_SAVE_FILE_NAME_URL as string,
  siteName: VITE_SITE_NAME as string,
  testAccount: 'admin',
  testPassword: '88888888',
  ddAppKey: VITE_DD_APPKEY,
  ddRedirectUrl: VITE_DD_REDIRECT_URL,
  appEnv: VITE_APP_ENV,
  isDevelopment: VITE_APP_ENV === 'development',
  isTest: VITE_APP_ENV === 'test',
  isProduction: VITE_APP_ENV === 'production',
}


