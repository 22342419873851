<template>
  <div>
    <router-view
      v-slot="{ Component }"
    >
      <template v-if="Component">
        <keep-alive :include="keepAliveInclude">
          <component
            :is="mainComponent(Component)"
          />
        </keep-alive>
      </template>
    </router-view>
  </div>
</template>
<script setup lang="ts">
import { TabInfo, useTabStore } from '@/store/modules/tab'
import { arrFind } from '@/utils/utils'
import { ComponentInternalInstance } from 'vue'
const instance = getCurrentInstance() as ComponentInternalInstance
const route = useRoute()
const appContext = instance.appContext
const store = useTabStore()
const mainComponent = computed(() => component => {
  const name = route.fullPath
  const tabTag:TabInfo = arrFind(store.tabTags, 'path', name)
  let content = store.loading ? null : component
  if (tabTag?.keepAlive) {
    if (!appContext.components[name]) {
      appContext.app.component(name, {
        name,
        render() {
          return component
        }
      })
    }
    content = h(appContext.components[name])
  }
  return content
})
const keepAliveInclude = computed(() => store.tabTags.filter(item => item.keepAlive).map(item => item.path))

</script>