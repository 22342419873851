<style lang="less" scoped>

</style>
<template>
  <div>
    <a-form-item-rest>
      <template v-if="dataSource.length === 0">
        <div
          v-if="props.textKeyMapArray.length > 0"
          class="w-full mb-2 text-center text-red-500 text-l"
        >
          **多sheet导入暂不支持校验数据和预览，请自行核对**
        </div>
        <!-- 设置弹性布局，无法使上传组件宽度100% -->
        <!-- <div class="flex justify-center"> -->
        <div class="w-full">
          <a-upload-dragger
            :file-list="fileList "
            :max-count="1"
            v-bind="bindAttrs"
            :multiple="false"
            @change="handleChange"
          >
            <p class="ant-upload-drag-icon">
              <basic-icon
                name="icon-upload_black_24dp"
                class="text-4xl"
              />
            </p>
            <p class="ant-upload-text">
              点击或拖拽文件到此上传
            </p>
            <p class="px-2 ant-upload-hint">
              可直接将文件拖拽到此处进行上传，支持格式: XLS、XLSX，大小不超过5M
            </p>
          </a-upload-dragger>
        </div>
        <!-- <Divider class="my-[10px]" /> -->
        <div class="flex justify-between my-2 text-red-500 ">
          上传前请确认：
          <span
            v-if="Boolean(props.downLoadUrl) && !renderDownloadBtn"
            class="hc-primary-color  cursor-pointer"
            @click="downloadFile(isFunction(props.downLoadUrl)?props.downLoadUrl():props.downLoadUrl, {}, props.excelName, 'xlsx')"
          >
            下载模板
          </span>
          <div v-if="renderDownloadBtn">
            <VueTsx :render="renderDownloadBtn" />
          </div>
        </div>
        <div
          v-for="(tip, index) in props.warningTexts"
          :key="index"
          :class="tip.class"
          class="mb-1 text-xs "
        >
          {{ tip.text }}
        </div>
      </template>

      <!-- 已上传文件，则预览数据 -->
      <div v-if="dataSource.length > 0">
        <a-button
          v-if="unValidateNumber.length > 0"
          danger
          size="small"
          class="float-right ml-2 "
          style="margin-top: 2px;"
          @click="downloadError(errorList)"
        >
          下载错误名单
        </a-button>
        <a-button
          class="float-right  ml-2"
          size="small"
          style="margin-top: 2px;"
          @click="toggle"
        >
          全屏预览
        </a-button>
        <a-button
          class="float-right "
          size="small"
          style="margin-top: 2px;"
          @click="init"
        >
          重新上传
        </a-button>
        <div class="flex justify-between mt-5 mb-4">
          <div>
            检测结果：共导入{{ dataSource.length }}条，<span class="text-green-500">{{ dataSource.length - unValidateNumber.length
            }}</span>条正常，<span class="text-red-500">{{ unValidateNumber.length }}</span>条异常
          </div>
        </div>
        <a-table
          ref="tableRef"
          :bordered="true"
          size="small"
          :scroll="{
            x: isFullscreen ? 'calc(100vh - 55px)' : tableWidth,
          }"
          :data-source="dataSource"
          :columns="columns"
          :pagination="showPagination && dataSource.length > size ? {
            total: dataSource.length,
            pageSize: size,
            showQuickJumper: false,
            showLessItems: true,
            onShowSizeChange: onShowSizeChange,
            showSizeChanger: true
          } : false"
        >
          <template #bodyCell="{ column, record }">
            <span>
              <div
                v-if="errorMsgReg.test(record[column.dataIndex])"
                class="text-red-500"
              >
                {{ record[column.dataIndex].substring(record[column.dataIndex].search(errorMsgReg),) }}
              </div>
              <div> {{ record[column.dataIndex]?.replace(errorMsgReg, '') }}</div>
            </span>
          </template>
        </a-table>
      </div>
      <a-modal
        v-model:open="visible"
        width="300px"
        :footer="false"
        :mask-closable="false"
      >
        <a-radio-group
          v-model:value="sheetIndex"
          class="mt-[10px]"
          @change="selectSheetName"
        >
          <a-radio
            v-for="(item,index) in modalSheet.sheetNames"
            :key="index"
            style="display: flex;line-height: 30px;height: 30px;"
            :value="index"
          >
            {{ item }}
          </a-radio>
        </a-radio-group>
        <div class="text-red-500 mt-[6px]">
          请选择解析的模版
        </div>
      </a-modal>
    </a-form-item-rest>
  </div>
</template>
<script lang="tsx" setup>
import { useMessage } from '@/hooks/message'
import { downloadFile } from '@/utils/file'
import { exportExcelDetail, getExcelOriginCell, getSheetHeaderAndData } from '@/utils/xlsx'
import { TableColumnsType } from 'ant-design-vue'
import { excelImportProps } from './props'
import { cloneDeep, isEmpty, omit, pick, isFunction } from 'lodash-es'
import { Table } from 'ant-design-vue/es'
import { catchData } from './common/catchData'
import { IExcelInfo } from './excel'
import { useEnv } from '@/hooks/env'
import { useUserStore } from '@/store'
import { dataFilter, formatDate, removePercentSign, validateData } from './common/utils'
import { formatToDate } from '@/utils/date'
import { UploadFile } from 'ant-design-vue/lib'
type UpdateParams = IExcelInfo['resultList'] | {list:IExcelInfo['resultList'], filePaths: Array<string> | string, fileId:Array<string | number>}
type EmitEvents = {
  (e: 'update:value', params:UpdateParams): void,
  (e:'resolveComplete', columns:TableColumnsType)
}
const excelInfo = ref<IExcelInfo>({
  resultList: [], // 最后需要传递给form的数据
})

// 文件路径数组
const filePaths = ref<string[]>([])

/** 校验携带错误信息的正则 包含 * * * 错误信息 * * * 则说明校验不通过*/
const errorMsgReg = /\*\*\*(.*?)\*\*\*/
const fileList = ref<UploadFile[]>([])
const dataSource = ref<Recordable[]>([])
let errorList = ref<Recordable[]>([])
const columns = ref<TableColumnsType>([])
const unValidateNumber = ref<number[]>([])
const tableRef = ref<InstanceType<typeof Table>>()
const tableWidth = ref(0)
const size = ref(5)
const emits = defineEmits<EmitEvents>()
const props = defineProps(excelImportProps)
const { toggle, isFullscreen } = useFullscreen(tableRef)
const attrs = useAttrs()


const visible = ref(false)
const modalSheet = ref<Recordable>({})
const sheetIndex = ref()

const onShowSizeChange = (current: number, pageSize: number) => {
  size.value = pageSize
}

const selectSheetName = () => {
  const { sheetList, sheetNames } = unref(modalSheet)
  const sheet = sheetList[unref(sheetIndex)]
  if (!sheet.length) {
    return useMessage.error('请选择正确的模板')
  }
  handleSheet([sheet], sheetNames)
  visible.value = false
}
const downloadError = async dataList => {
  let textKeyMaps = Object.keys(props.textKeyMap).map(key => {
    let k = key
    if (key.includes('|')) {
      k = key?.split('|')[0]
    }
    return { [k]: props.textKeyMap[key] }
  })
  await exportExcelDetail({
    textKeyMaps,
    dataList,
    excelName: `${props.excelName}信息有误数据表`
  })
}
// 获取上传接口调用的请求头
const getHeaders = computed(() => ({
  ...pick(attrs, 'headers'),
  Authorization: `Bearer ${useUserStore().token}`,
}))
const getParams = computed(() => ({
  ...pick(attrs, 'data'),
  type: '.xlsx',
}))
const bindAttrs = computed(() => {
  let tempAttrs = {} as Recordable
  if (props.isUpload) {
    tempAttrs = {
      beforeUpload: file => file,
      action: useEnv.uploadApiUrl,
      headers: unref(getHeaders),
      data: unref(getParams),
      accept: '.xlsx',
      ...omit(attrs, ['headers', 'data']),
    }
  } else {
    tempAttrs.beforeUpload = () => false
  }
  return tempAttrs
})


// 重置表格数据
const resetTable = () => {
  fileList.value = []
  dataSource.value = []
  excelInfo.value.resultList = []
  emits('update:value', unref(excelInfo).resultList)
}

const init = () => {
  if (props.multipleFirstSheet) {
    modalSheet.value = {}
    sheetIndex.value = undefined
  }
  resetTable()
  emits('update:value', [])
}

async function handleChange({ file, fileList: _fileList }) {
  fileList.value = _fileList // 设置为受控组件，需要将 uploading 状态的 file 同步到 files 中，使的上传功能继续执行，才能触发 done 等上传结束事件
  unValidateNumber.value = []
  if (file.status !== 'done' && file.status !== 'removed' && props.isUpload) {
    return
  }


  if (file.status === 'removed') {
    resetTable()
    return
  }
  filePaths.value = toRaw(_fileList)
    .filter(f => f.status === 'done')
    .map(f => {
      if (f.response) { // 新上传的文件
        return f.response.data.path
      } else { // 数据回填的文件
        return new URL(f.url!).pathname
      }
    })


  const { excelData: sheetList, sheetNames } = await getExcelOriginCell(props.isUpload ? file.originFileObj : file) // 获取 excel 表所有 sheet 所有单元格数据
  if (sheetList.length > 1 && props.multipleFirstSheet) {
    visible.value = true
    modalSheet.value = {
      sheetNames,
      sheetList
    }
  } else {
    handleSheet(sheetList, sheetNames)
  }
}

function handleSheet(sheetList, sheetNames) {
  // 多sheet导入时计算所有sheet的数据总数，单sheet导入只计算第一个sheet的数据长度
  if (props.maxImportNum < (props.textKeyMapArray.length > 0 ? sheetList.reduce((sum, sheet) => sum + sheet.length, 0) : sheetList[0].length)) {
    useMessage.error(`最多导入${props.maxImportNum}条（包含表头）`)
    resetTable()
    return
  }
  console.log('props.textKeyMapArray', props.textKeyMapArray)

  if (props.textKeyMapArray.length > 0) {
    const { excelList, status } = multipleSheets(sheetList, sheetNames)
    if (!status) {

      useMessage.error('模板错误，请选择正确模板')
      return
    }
    excelInfo.value.resultList = excelList
  } else {

    /**
     * valiDateDataList 通过校验数据
     * unValiDateDataList 错误数据
     * errorAndOriginalDataList 所有数据
     * status 模板是否正确
     * **/
    let { headerColumns, valiDateDataList, unValiDateDataList, errorAndOriginalDataList, status } = singleSheet(sheetList)
    if (!status || !valiDateDataList.length) {
      useMessage.error('很抱歉，我们无法解析您提供的数据。请重新上传您需要导入的数据')
      return
    }

    // todo 远程校验待开发
    // if (valiDateDataList?.length > 0) {
    //   if (isFunction(props.batchApiValidate)) {
    //     let res = await props.batchApiValidate(valiDateDataList)
    //     res = res.map(item => ({
    //       ...item,
    //       errMsg: item[props.errKey] ?? ''
    //     }))
    //     unValiDateDataList = [...unValiDateDataList, ...res]
    //   }
    // }

    console.log('headerColumns', headerColumns) // table 表头结构
    console.log('errorAndOriginalDataList', errorAndOriginalDataList) // table 表格数据
    console.log('valiDateDataList', valiDateDataList) // 传递给后端的数据
    tableWidth.value = headerColumns.length * 110
    columns.value = headerColumns
    dataSource.value = errorAndOriginalDataList
    errorList.value = unValiDateDataList
    excelInfo.value.resultList = valiDateDataList
  }
  catchData(excelInfo.value.resultList, () => {
    const emitsValue:UpdateParams = props.isUpload ? {
      list: unref(excelInfo).resultList,
      filePaths: toRaw(unref(fileList))
        .filter(f => f.status === 'done')
        .map(f => {
          if (f.response) { // 新上传的文件
            return f.response.data.path
          } else { // 数据回填的文件
            return new URL(f.url!).pathname
          }
        }),
      fileId: toRaw(unref(fileList))
        .filter(f => f.status === 'done')
        .map(f => {
          if (f.response) { // 新上传的文件
            return f.response.data.id
          }
        })
    } : unref(excelInfo).resultList
    emits('update:value', emitsValue)
    emits('resolveComplete', columns.value)
  })
}
// 单个sheet导入
function singleSheet(sheetList) {
  // 删除行 目前只做了单sheet导入支持
  sheetList[0].splice(0, props.removeRow)
  let { headerColumns, dataList, dataSourceList } = getSheetHeaderAndData(sheetList[0], props.textKeyMap)
  if (props.textKeyMapMatch) {
    headerColumns = headerColumns.filter(item => props.textKeyMap.hasOwnProperty(item.title))
  }
  const headerColumnsTextKey = headerColumns.map(i => i.title)
  let textKey:any = Object.keys(props.textKeyMap)

  // 根据表头生成textKey
  textKey = headerColumnsTextKey
  headerColumns.forEach(column => {
    if (!column.dataIndex) {
      column.dataIndex = column.title
    }
  })

  dataList = dataList.filter(item => !isEmpty(item))
  let status = false
  // 单sheet导入通过判断配置的表头名字来验证是否选错导入表格(为了可以自定义表头导入，只要选择的表头包含了配置的表头既正确)
  textKey.forEach(key => {
    status = headerColumnsTextKey.includes(key)
  })
  let errorAndOriginalDataList: Recordable[] = []
  let tempUnValidateNumber: number[] = []
  let cloneDataList = cloneDeep(dataList)
  cloneDataList.forEach((item, index) => {
    // 是否去掉百分号
    if (props.needRemovePercentKey.length) {
      dataList[index] = removePercentSign(cloneDataList, props.needRemovePercentKey, item, index)
    }
    // 先格式化再验证，不会显示invalid date
    // 是否格式化日期
    if (props.needFormatDataKey.length) {
      dataList[index] = formatDate(props.needFormatDataKey, props.format, item)
    }
    // 是否校验数据
    if (!isEmpty(props.validateKeyAndType)) {
      let { cloneItem } = validateData(props.validateKeyAndType, tempUnValidateNumber, item, index)
      errorAndOriginalDataList.push(cloneItem)
    } else {
      errorAndOriginalDataList.push(item)
    }

    // 转化日期
    for (const key in item) {
      if (/^[0-9]{1,2}\/[0-9]{1,2}(\/[0-9]{1,2})?$/.test(item[key])) {
        item[key] = formatToDate(item[key])
      }
    }

  })
  console.log(unValidateNumber.value, 'unValidateNumber.value', cloneDataList)
  unValidateNumber.value = Array.from(new Set(tempUnValidateNumber)) // 去除重复索引
  // 过滤出没通过校验的数据
  let unValiDateDataList = errorAndOriginalDataList.filter((item, index) => unValidateNumber.value.includes(index))
  //  过滤出通过校验的数据
  let valiDateDataList = cloneDataList.filter((item, index) => !unValidateNumber.value.includes(index))
  console.log(valiDateDataList, '通过校验的数据', unValiDateDataList, '错误的数据')
  // if (unValiDateDataList?.length > 0) {
  //   useMessage.error('没有通过校验的信息，请核对')
  //   resetTable()
  //   return
  // }
  // 是否过滤数据
  if (props.isFilter) {
    valiDateDataList = dataFilter(valiDateDataList, props.filterDependedKeyAndString!)
  }
  return {
    headerColumns,
    errorAndOriginalDataList,
    valiDateDataList,
    unValiDateDataList,
    status
  }
}
// 多个sheet导入
function multipleSheets(sheetList: string[][][], sheetNames: string[]) {
  let excelList = [] as IExcelInfo['resultList']
  let status = true
  // 多sheet导入通过判断sheet的名字来验证是否选错导入表格
  if (sheetNames.join(',') !== props.sheetNames.join(',')) {
    status = false
    resetTable()
    return {
      excelList,
      status
    }
  }
  for (let i = 0; i < sheetList.length; i++) {
    if (props.notResolvedSheetIndex.includes(i)) { // 跳过不需要解析的sheet
      continue
    }
    let { headerColumns, dataList, dataSourceList } = getSheetHeaderAndData(sheetList[i], props.textKeyMapArray[i].textKeyMap)
    // 是否去掉百分号
    if (props.textKeyMapArray[i].needRemovePercentKey) {
      let cloneDataList = cloneDeep(dataList)
      cloneDataList.forEach((item, index) => {
        item = removePercentSign(cloneDataList, props.textKeyMapArray[i].needRemovePercentKey!, item, index)
      })
      dataList = cloneDataList
    }
    // 是否过滤数据
    if (props.textKeyMapArray[i].isFilter) {
      dataList = dataFilter(dataList, props.textKeyMapArray[i].filterDependedKeyAndString!)
    }
    excelList.push({
      headerColumns,
      dataList,
      dataSourceList
    })
  }
  return {
    excelList,
    status
  }
}

</script>
