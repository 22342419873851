import initDefaultProp from 'ant-design-vue/es/_util/props-util/initDefaultProps'
import { forEach, isEmpty, isNumber, toArray } from 'lodash-es'
// 数组组成树形
export function buildTree(data:any[], pidField = 'parent_id', children = 'children') {

  const lookup:any = {}

  // Create a lookup object
  data.forEach(item => {
    const row = { ...item }
    row[children] = []
    lookup[item.id] = row
  })

  // Build the tree structure
  const deleteIds:number[] = []
  data.forEach(item => {
    if (lookup[item[pidField]]) {
      lookup[item[pidField]]?.[children]?.push(lookup[item.id])
      deleteIds.push(item.id)
    }
  })
  forEach(lookup, item => {
    if (item[children].length == 0) {
      delete item[children]
    }
  })
  deleteIds.forEach(id => {
    delete lookup[id]
  })
  return toArray(lookup)
}
// 递归查找数组
export function arrFind(arr: any, field: string, value: any, children = 'children'): any {
  for (let key in arr) {
    if (Object(arr[key])[field] == value) {
      return arr[key]
    }
    if (Object(arr[key])[children]) {
      let find = arrFind(Object(arr[key])[children], field, value, children)
      if (find) {
        return find
      }
    }
  }
  return null
}
export function findParent(arr: object[], field: string, value: any, children = 'children', parents: object[] = []): any {
  for (let key in arr) {
    if (Object(arr[key])[field] == value) {
      return parents
    }
    if (Object(arr[key])[children]) {
      let find = findParent(Object(arr[key])[children], field, value, children, parents)
      if (find) {
        parents.unshift(Object(arr[key]))
        return find
      }
    }
  }
  return null
}
export function findParentList(arr: any[], value: any, field = 'id', pidField = 'pid', childrenName = 'children') {
  let list = []; let find
  do {
    find = arrFind(arr, field, value, childrenName)
    if (find) {
      list.unshift(find)
      value = find[pidField]
    }
  } while (find)
  return list
}
export function arrFindNotChildren(arr: object[]): any {
  for (let key in arr) {
    if (Object(arr[key]).children) {
      const item = arrFindNotChildren(Object(arr[key]).children)
      if (item) {
        return item
      }
    }
    return arr[key]
  }
  return null
}
export function arrMap(arr: object[], callbackfn: Function, children = 'children', parents: object[] = []): any[] {
  return arr.map(item => {
    const result = callbackfn(item, parents)
    if (Object(item)[children]) {
      parents.push(item)
      arrMap(Object(item)[children], callbackfn, children, parents)
      parents = []
    }
    return result
  })
}
export function depthToArr(arr: object[], children = 'children', clone = true): any[] {
  let data: object[] = []
  arr.map(item => {
    data.push(clone ? { ...item } : item)
    if (Object(item)[children]) {
      data = data.concat(depthToArr(Object(item)[children], children))
    }
  })
  return data
}
export function lastName(path: string, filename = '') {
  path = path.toString()
  if (filename) {
    return filename
  } else {
    const index = path.lastIndexOf('\/')
    return path.substring(index + 1, path.length)
  }
}
export function fileIcon(path: string) {
  path = path.toString()
  const index = path.toString().lastIndexOf('\.')
  const ext = path.substring(index + 1, path.length)
  try {
    return `${import.meta.env.BASE_URL}file_icon/${ext}.png`
  } catch (e) {
    return null
  }
}

// 上移
export function ArrUp(arr: any[], index: number) {
  if (index > 0) {
    const len = arr[index - 1]
    arr[index - 1] = arr[index]
    arr[index] = len
  }

}
// 下移
export function ArrDown(arr: any[], index: number) {
  if (index < arr.length - 1) {
    const len = arr[index + 1]
    arr[index + 1] = arr[index]
    arr[index] = len
  }
}
// hex转rgba
export function hexToRgba(hex:string, opacity = 1) {
  let result = /^#?([a-f\d]{6}|[a-f\d]{3})$/i.exec(hex)

  if (result) {
    let r = parseInt(result[1].substr(0, 2), 16)
    let g = parseInt(result[1].substr(2, 2), 16)
    let b = parseInt(result[1].substr(4, 2), 16)
    return `rgba(${r},${g},${b},${opacity})`
  } else {
    return null
  }
}
// 判断非空
export function isNotEmpty(value:any) {
  return !isEmpty(value) || isNumber(value)
}
export const initDefaultProps = initDefaultProp
