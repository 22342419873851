import { ExtractPropTypes, PropType } from 'vue'

export const exportTableSelectProps = {
  value: {
    type: Object as PropType<object>,
  },
  demoUrl: {
    type: [String, Function],
    // 默认对私
    default: 'https://saas-1301695016.cos.ap-guangzhou.myqcloud.com/web/%E5%AF%B9%E7%A7%81-%E4%B8%8B%E5%8F%91%E6%98%8E%E7%BB%86%E6%A8%A1%E6%9D%BF.xlsx'
  },
  helpTextPrefix: {
    type: String,
    default: '请按'
  },
  helpText: {
    type: String,
    default: '模板'
  },
  requireField: {
    type: Array as PropType<string[]>,
    default: ['realname', 'mobile', 'idcard', 'bankAccount', 'shouldMoney', 'money', 'shebaoMoney', 'gjjMoney', 'feeMoney', 'taxMoney', 'entryAt'],
  },
  scroll: {
    type: Object,
    default: {
      x: 2000,
      y: 500
    }
  },
  fieldMap: {
    type: Object as PropType<object>,
    default: {
      realname: '姓名(必填)',
      entryAt: '入职日期(必填)',
      mobile: '联系电话(必填)',
      idcard: '身份证号(必填)',
      bankAccount: '银行卡号(必填)',
      shouldMoney: '应发金额(必填)',
      shebaoMoney: '个人社保(必填)',
      gjjMoney: '个人公积金(必填)',
      feeMoney: '服务费(必填)',
      taxMoney: '个税(必填)',
      money: '实发金额(必填)',
      bankName: '开户行',
      bankSn: '银行联行号',
      remark: '备注',
    },
  },
  helpMessage: {
    type: String,
    default: undefined
  },
}


export type ExportTableSelectProps = Partial<ExtractPropTypes<typeof exportTableSelectProps>>