import { generateLinkColorsRGBA } from '@/utils/color'
import { defineStore } from 'pinia'
type ThemeType = 'light' | 'dark'
type HeaderThemeType = 'white' | 'theme'
type NavigationMode = 'mix' | 'side' | 'top'
interface AppConfig {
  theme: ThemeType;
  themeColor: string;
  backgroundColor: string;
  navigationMode: NavigationMode;
  splitMenu: boolean;
  tabTag: boolean;
  sidebar: SidebarConfig;
  header: HeaderConfig;
}
interface SidebarConfig {
  theme: ThemeType;
  open: boolean;
  collapsed: boolean;
  width: number;
  collapsedWidth: number;
  color?: string;
  selectedColor?: string;
  backgroundColor?: string;
}

interface HeaderConfig {
  height: number;
  theme: HeaderThemeType;
  color?: string;
  selectedColor?: string;
  backgroundColor?: string;
  selected_color?: string;
  background_color?: string;
}

interface SystemState {
  isPc: boolean;
  isMobile: boolean;
  miniScreen: boolean;
  menuCollapsed: boolean;
  layout: AppConfig
  selectedTopMenu: string;
  iframeModel: boolean;
  homePath: string;
}
export const useSystemStore = defineStore('system', {
  state: (): SystemState => ({
    isPc: true,
    miniScreen: false,
    menuCollapsed: false,
    iframeModel: false, // 嵌入页面
    isMobile: false,
    selectedTopMenu: '', // header选中菜单
    homePath: '/workbench', // 切割菜单首页不显示菜单
    // 布局配置
    layout: {
      // 主题 light 暗黑dark
      theme: 'light',
      // 主题颜色const #ff5722 1373c5
      themeColor: '#ff5722',
      // 布局背景色
      backgroundColor: '#f0f2f5',
      // 导航模式
      navigationMode: 'mix',
      // 分割菜单
      splitMenu: false,
      // 开启标签
      tabTag: true,
      // 侧边栏
      sidebar: {
        // 主题 light 暗黑dark
        theme: 'light',
        // 显示隐藏
        open: true,
        // 是否收起状态
        collapsed: false,
        // 宽度
        width: 200,

        collapsedWidth: 50,
        // //文字颜色
        // color: '#393939',
        // //选中颜色
        // selected_color: '#1890ff',
        // //背景色
        // background_color: '#ffffff',
      },
      // 顶部栏
      header: {
        height: 52,
        // 主题 white theme
        theme: 'white',
        // 文字颜色
        color: '#f0f2f5',
        // 选中颜色
        selectedColor: '#f0f2f5',
        // 背景色
        backgroundColor: '#f0f2f5',
      }
    },
  }),
  getters: {},
  actions: {
    toggleDevice(isPc: boolean) {
      this.isPc = isPc
    },
    setMiniScrren(v: boolean) {
      this.miniScreen = v
    },
    getThemeColor(factor = 1, opcity = 1) {
      return generateLinkColorsRGBA(this.layout.themeColor, factor, opcity)
    },
    setIframeMode() {
      this.iframeModel = true
    }
  },
})
