<style lang="less" scoped>
.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;

  > * {
    margin-left: 10px;
  }
}
</style>

<template>
  <div class="flex justify-end">
    <div>
      <a-space>
        <template v-for="action in actions">
          <template v-if="action.type === 'dropdown'">
            <a-dropdown
              v-if="size(dropdownActionable(action.children))"
              :key="action.label"
            >
              <a-button
                type="primary"
                ghost
              >
                {{ action.label }}
                <down-outlined />
              </a-button>
              <template #overlay>
                <a-menu>
                  <template v-for="dropAction,ix in dropdownActionable(action.children)">
                    <a-popconfirm
                      v-if="dropAction.confirm"
                      :key="dropAction.label"
                      :on-confirm="() => handleClickAction(dropAction)"
                      :title="dropAction.confirm"
                      placement="left"
                      size="small"
                    >
                      <a-menu-item>
                        {{ dropAction.label }}
                      </a-menu-item>
                    </a-popconfirm>
                    <a-menu-item
                      v-else
                      :key="dropAction.label+ix"
                      @click="() => handleClickAction(dropAction)"
                    >
                      {{ dropAction.label }}
                    </a-menu-item>
                  </template>
                </a-menu>
              </template>
            </a-dropdown>
          </template>
          <a-button
            v-else
            v-bind="action"
            :key="action.label"
            @click="() => handleClickAction(action)"
          >
            {{ action.label }}
          </a-button>
        </template>


        <!-- 更多收起按钮 废弃: 用法 type:dropdown -->
        <!-- <a-dropdown v-if="dropdownActions?.length > 0">
          <a-button
            type="primary"
            ghost
          >
            更多
            <down-outlined />
          </a-button>
          <template #overlay>
            <a-menu>
              <template v-for="action,ix in dropdownActions">
                <a-popconfirm
                  v-if="action.confirm"
                  :key="action.label"
                  :on-confirm="() => handleClickAction(action)"
                  :title="action.confirm"
                  placement="left"
                  size="small"
                >
                  <a-menu-item>
                    {{ action.label }}
                  </a-menu-item>
                </a-popconfirm>
                <a-menu-item
                  v-else
                  :key="action.label+ix"
                  @click="() => handleClickAction(action)"
                >
                  {{ action.label }}
                </a-menu-item>
              </template>
            </a-menu>
          </template>
        </a-dropdown> -->

        <!-- 批量勾选按钮 -->
        <a-dropdown v-if="multiActions?.length > 0">
          <a-button
            type="primary"
            ghost
          >
            批量操作
            <down-outlined />
          </a-button>
          <template #overlay>
            <a-menu>
              <template v-for="action,ix in multiActions">
                <a-popconfirm
                  v-if="action.confirm"
                  :key="action.label"
                  :on-confirm="() => handleClickAction(action)"
                  :title="action.confirm"
                  placement="left"
                  size="small"
                >
                  <a-menu-item :disabled="!multiActionable">
                    {{ action.label }}
                  </a-menu-item>
                </a-popconfirm>
                <a-menu-item
                  v-else
                  :key="action.label+ix"
                  :disabled="!multiActionable"
                  @click="() => handleClickAction(action)"
                >
                  {{ action.label }}
                </a-menu-item>
              </template>
            </a-menu>
          </template>
        </a-dropdown>
      </a-space>
    </div>
    <div
      v-if="showTools"
      class="tools"
    >
      <a-button
        v-if="props.creatable"
        type="primary"
        @click="handleCreate"
      >
        {{ createText }}
      </a-button>
      <a-divider type="vertical" />
      <a-tooltip title="刷新">
        <redo-outlined
          style="font-size: 16px;"
          @click="() => context.reload()"
        />
      </a-tooltip>
      <span v-if="props.exportable">
        <table-exporter :export-handler="props.exportHandler" />
      </span>
      <span>
        <column-setting />
      </span>
      <a-tooltip :title="getTitle">
        <fullscreen-exit-outlined
          v-if="isFullscreen"
          style="font-size: 16px;"
          @click="toggle"
        />
        <fullscreen-outlined
          v-else
          style="font-size: 16px;"
          @click="toggle"
        />
      </a-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '@/store'
import { useTableContext } from '../hooks/context'
import TableExporter from './table-exporter.vue'
import ColumnSetting from './column-setting.vue'
import { useSystemStore } from '../../../store/modules/system'
import { size } from 'lodash-es'
const userStore = useUserStore()
const context = useTableContext()
const systemStore = useSystemStore()
const { toggle, isFullscreen } = useFullscreen(context.wrapLlRef)
type EmitEvents = {
  (e: 'create'): void;
  (e: 'setFullscreen', isFullscreen: boolean): void;
}
const emits = defineEmits<EmitEvents>()
const props = computed(() => context.getProps().value)
const multiActionable = computed(() => context.getSelectionKeys().length > 0)
const validActions = computed(() =>
  unref(props.value.tableActions?.filter(a =>
    a.isShow?.() !== false && userStore.hasPermission(a.requiredPermission)) || []))
const dropdownActionable = actions => (actions.filter(a => a.isShow?.() !== false && userStore.hasPermission(a.requiredPermission)) ?? [])
const multiActions = computed(() =>
  unref(validActions.value.filter(a => a.needSelection)))
const actions = computed(() =>
  unref(validActions.value.filter(a => !a.needSelection && (!a.dropdownable && systemStore.isPc))))
// const dropdownActions = computed(() => unref(validActions.value.filter(a => !a.needSelection && (a.dropdownable || !systemStore.isPc)))) // 移动端统一收起按钮
const createText = computed(() => props.value.createText ?? `新增${props.value.title}`)
const getTitle = computed(() => (isFullscreen.value ? '退出全屏' : '全屏'))
const showTools = computed(() => props.value.showTools)
// 默认值没有用，这里直接批量操作
// const tableNeedSelectionText = computed(() => props.value.tableNeedSelectionLabel || '批量操作')
watch(isFullscreen, () => {
  emits('setFullscreen', isFullscreen.value)
})

function handleCreate() {
  emits('create')
}

async function handleClickAction(action: IGridTable.Action) {

  if (action.needSelection) {
    await action.click(
      toRaw(unref(context.getSelectionKeys())),
      toRaw(unref(context.getSelectionRows()))
    )
    if (action.needCancelSelect) {
      context.setSelectedKeys([])
    }
  } else {
    await action.click()
  }
  action.needReload && context.reload()
}
</script>
