import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw } from 'vue-router'

export const workbenchRoutes: CustomRouteRecordRaw = {
  path: 'workbench',
  name: 'workbench',
  redirect: { name: 'workbenchIndex' },
  component: EmptyLayout,
  meta: {
    title: '工作台',
    noQuick: true,
    iconfont: 'icon-gongzuotai1',

  },
  children: [
    // 项目列表
    {
      path: 'workbench',
      name: 'workbenchIndex',
      component: () => import('@/views/workbench/workbench.vue'),
      meta: {
        iconfont: 'icon-gongzuotai1',
        title: '工作台',
        noQuick: true,

      },
    },
  ],
}
