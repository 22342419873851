import type { CustomRouteRecordRaw } from 'vue-router'
import { systemRoutes } from './system'
import { useSiteConfig } from '@/hooks/site-config'
import BackendLayout from '@/views/layouts/backend-layout.vue'
import { projectRoutes } from './project'
import { workerRoutes } from './worker'
// import { attenanceRoutes } from './attendance'
// import { customerRoutes } from './customer'
// import { exampleRoutes } from './example'
import { clientRoutes } from './client'
import { workbenchRoutes } from './workbench'
import { wageRoutes } from './wage'
import { examineRoutes } from './examine'
import { welfareRoutes } from './welfare'
// import { commercialInsuranceRoutes } from './commercialInsurance'
const { backendPrefix } = useSiteConfig

export const appRoutes: CustomRouteRecordRaw[] = [
  // customerRoutes,
  workbenchRoutes,
  projectRoutes,
  workerRoutes,
  // attenanceRoutes,
  welfareRoutes,
  examineRoutes,
  wageRoutes,
  systemRoutes,
  clientRoutes,
  // commercialInsuranceRoutes,
  // exampleRoutes
]

const routes: CustomRouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/login/login.vue'),
    meta: {
      title: '账号登录',
      noNeedAuth: true,
      noQuick: true,
      hideHistoryPage: true

    },
  },
  {
    path: '/login-loading',
    name: 'loginLoading',
    component: () => import('@/views/auth/login-loading.vue'),
    meta: {
      title: '授权登录中',
      noNeedAuth: true,
      noQuick: true
    },
  },
  {
    path: '/',
    name: 'root',
    component: BackendLayout,
    redirect: '/workbench',
    meta: {
      noQuick: true
    },
    children: [],
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/example/test.vue'),
    meta: {
      title: '测试',
      noNeedAuth: true,
      noQuick: true
    },
  },
  {
    path: '/:patchMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/404.vue'),
    meta: {
      title: '页面不存在',
      noNeedAuth: true,
      noQuick: true
    },
  },
]

// 获取父路由的名字
function setParentName(routeList, parentNameList) {
  routeList.forEach(route => {
    route.meta = route.meta || {}
    route.meta.parentName = parentNameList
    route.children?.length
      && setParentName(route.children, [...parentNameList, route.name])
  })
}
setParentName(routes, [])

export default routes

