<template>
  <a-config-provider
    :locale="zhCN"
    :transform-cell-text="({ text }) => text ? text : '--'"
    :theme="theme"
  >
    <div>
      <router-view />
      <modal-form
        v-if="globalModelForm._isRender"
        v-bind="(globalModelForm.modelFormAttr as Readonly<ExtractPropTypes<typeof modalFormProps>>)"
        :ref="el => globalModelForm.modelFromRef = el"
        :open="globalModelForm.open"
        @ok="() => globalModelForm.open = false"
        @cancel="() => globalModelForm.open = false"
      />
    </div>
  </a-config-provider>
</template>
<script lang="ts" setup>
import type { ExtractPropTypes } from 'vue'
import { modalFormProps } from '@/components/form/props'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import ModalForm from './components/form/modal-form.vue'
import globalModelForm from '@/hooks/global-model-form'
import { useWindowSizeFn } from './hooks/window-size-fn'
import { ThemeConfig } from 'ant-design-vue/es/config-provider/context'
import { useSystemStore } from './store/modules/system'
import { theme as antdTheme } from 'ant-design-vue/es'
import { router } from '@/router'

const store = useSystemStore()
const theme = computed((): ThemeConfig => {
  let color = store.layout.themeColor
  const getThemeColor = store.getThemeColor
  const configLink = {
    colorLink: color,
    colorLinkHover: getThemeColor(0.8),
    colorLinkActive: getThemeColor(1.5),
  }
  setPrimaryColor(color)
  return {
    algorithm: store.layout.theme === 'dark' ? antdTheme.darkAlgorithm : antdTheme.defaultAlgorithm,
    token: {
    // colorPrimary: ,
      colorPrimary: color,
    },
    components: {
      Button: configLink,
      Typography: configLink,
      Menu: {
        colorSubItemBg: store.layout.theme === 'dark' || store.layout.sidebar.theme === 'dark' ? '#141414' : '#FFFFFF',
      },
      Layout: {
        colorBgBody: store.layout.backgroundColor
      }
    }
  }
})

function setPrimaryColor(color) {
  document.documentElement.style.setProperty('--hc-primary-color', color)
  document.documentElement.style.setProperty('--hc-primary-color-1f', `${color}1F`)
  document.documentElement.style.setProperty('--hc-bg-white', store.layout.theme === 'dark' ? '#141414' : '#FFFFFF')
}

window.addEventListener('message', event => {
  if (event.data) {
    let data = JSON.parse(event.data)
    data?.path && router.push(data?.path)
  }

})


const setDevider = () => {
  const { body } = document
  const rect = body.getBoundingClientRect()
  const isMobile = rect.width - 1 < 992 && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const miniScreen = rect.width - 1 < 1280
  store.toggleDevice(!isMobile)
  store.setMiniScrren(miniScreen)
}

useWindowSizeFn(setDevider)
setDevider()

</script>