
<style scoped lang="less">
.tabs {
  display: flex;
  align-items: center;
  margin: 0 0 12px;
  height: 30px;

  .left {
    position: relative;
    display: flex;
    margin-right: 5px;
    height: 100%;
    width: 0;
    flex: 1;
  }

  .scroll-content {
    position: relative;
    display: flex;
    overflow-x: auto;
    height: 100%;
  }
}

.scroll-content::-webkit-scrollbar {
  display: none;
}

.left::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 32px;
  opacity: 0;
  box-shadow: inset 10px 0 8px -8px rgb(0 0 0 / 8%);
  transition: opacity 0.3s;
  content: "";
  pointer-events: none;
}

.left::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 32px;
  opacity: 0;
  box-shadow: inset -10px 0 8px -8px rgb(0 0 0 / 8%);
  transition: opacity 0.3s;
  content: "";
  pointer-events: none;
}

.scroll-content-left::before {
  opacity: 1;
}

.scroll-content-right::after {
  opacity: 1;
}

.tabs .right {
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 30px;
}

.tabs .right .tool + .tool {
  margin-left: 10px;
}

.tabs .right .tool {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0 8px;
  height: 30px;
  background: white;
  cursor: pointer;
}

.tabs .right .tool:hover {
  background: rgb(0 0 0 / 8.5%);
}

.tabs .item {
  display: flex;
  align-items: center;
  border: solid 1px #F0F0F0;
  border-radius: 5px;
  padding: 0 6px 0 12px;
  height: 100%;
  white-space: nowrap;
  color: #999999;
  background: rgb(0 0 0 / 4%);
  cursor: pointer;
}

.tabs .item + .item {
  margin-left: 5px;
}

.tabs .item:hover {
  background: white;
}

.tabs .active {
  color: #000000D9;
  background: white;
}

.close {
  margin-left: 10px;
  padding-right: 2px;
  font-size: 12px;
  color: #CCCCCC;
}

.reload {
  margin-left: 10px;
  font-size: 12px;
  color: #CCCCCC;
}

.reload:hover {
  color: #333333;
}

.close:hover {
  border-radius: 50%;
  padding: 1px;
  color: #FFFFFF;
  background: #C0C4CC;
}

.dark {
  .tabs {
    .active {
      color: #FFFFFF;
    }

    .item {
      border: solid 1px #434343;
      background: #141414;
    }

    .tool {
      color: rgb(255 255 255 / 85%);
      background: #141414;
    }
  }
}
</style>


<template>
  <div
    class="tabs"
    :class="[hideTab ? 'h-0 overflow-hidden m-0 p-0' : 'px-4']"
  >
    <div
      :class="['left', hasScrollbar && !left ? 'scroll-content-left' : null, hasScrollbar && !right ? 'scroll-content-right' : null]"
    >
      <div
        ref="el"
        class="scroll-content"
        @wheel="handleWheel"
      >
        <div
          v-for="(item, key) in tags"
          :key="item.path"
          :id="'ex-tabs-tab-'+item.path"
          tabindex="0"
          :class="['item', item.path == getPath() ? 'active' : '']"
          @click="router.push(item.path)"
        >
          <ABadge :color="item.path === getPath() ? systemStore.layout.themeColor : '#CCCCCC'" />
          <span>{{ item.title }}</span>
          <a-tooltip
            v-if="item.path === getPath()"
            placement="bottom"
          >
            <template #title>
              <span>刷新</span>
            </template>
            <ReloadOutlined
              :class="['reload', store.loading ? 'rotate-animation' : undefined]"
              @click="refresh"
            />
          </a-tooltip>

          <close-outlined
            class="close"
            @click.stop="close(key)"
          />
        </div>
      </div>
    </div>
    <div class="right">
      <a-tooltip placement="bottom">
        <template #title>
          <span>返回上一页</span>
        </template>
        <div
          class="tool"
          @click="back"
        >
          <basic-icon
            name="icon-fanhui"
          />
        </div>
      </a-tooltip>

      <a-dropdown>
        <div class="tool">
          <MoreOutlined />
        </div>
        <template #overlay>
          <a-menu @click="handleClick">
            <a-menu-item key="other">
              <template #icon>
                <CloseOutlined />
              </template>
              关闭其他
            </a-menu-item>
            <a-menu-item key="left">
              <template #icon>
                <ArrowLeftOutlined />
              </template>
              关闭左侧
            </a-menu-item>
            <a-menu-item key="right">
              <template #icon>
                <ArrowRightOutlined />
              </template>
              关闭右侧
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { useTabStore } from '@/store/modules/tab'
import { useSystemStore } from '@/store'
import { computed, ref, toRefs, watch } from 'vue'
import { CloseOutlined, ArrowLeftOutlined, ArrowRightOutlined, MoreOutlined, ReloadOutlined } from '@ant-design/icons-vue'
import { useResizeObserver, useScroll } from '@vueuse/core'
import { TAB_TAGS } from '@/utils/storage'
defineProps({
  // 隐藏tab,功能历史标签保留
  hideTab: {
    type: Boolean,
    defualt: false
  }
})

const store = useTabStore()
const systemStore = useSystemStore()
const route = useRoute()
const router = useRouter()
const tags = computed(() => store.tabTags)
const el = ref(null)
const hasScrollbar = ref(false)
const { arrivedState, x } = useScroll(el)
const { left, right } = toRefs(arrivedState)
useResizeObserver(el, () => {
  const container = el.value as unknown as Element
  hasScrollbar.value = container.scrollWidth > container.clientWidth
})
watch(() => route.path, () => {
  store.addTabTag(route)
  // tabs没有固定顶部
  nextTick(() => {
    document.getElementById(`ex-tabs-tab-${route.fullPath}`)?.scrollIntoView()
  })
}, { immediate: true })

watch(store.tabTags, value => {
  localStorage.setItem(TAB_TAGS, JSON.stringify(value))
})
// 后退
function back() {
  router.back()
}
function getPath() {
  return decodeURIComponent(route.fullPath)
}
// 关闭当前
function close(key: number) {
  if (store.tabTags.length > 1) {
    const tags = store.tabTags.filter(item => item.path != store.tabTags[key].path)
    if (route.fullPath == store.tabTags[key].path) {
      router.push(Object(tags[tags.length - 1]).path)
    }
    setTimeout(() => store.tabTags.splice(key, 1))
  }
}
// 关闭菜单
function handleClick({ key }: { key: string }) {
  const index = store.findTabTagIndex(route)
  if (key === 'left') {
    store.tabTags.splice(0, index)
  } else if (key === 'right') {
    store.tabTags.splice(index + 1)
  } else if (key === 'other') {
    store.tabTags = store.tabTags.filter(item => route.fullPath === item.path)
    localStorage.setItem(TAB_TAGS, JSON.stringify(store.tabTags))
  }
}
// 监听滚轮滚动
function handleWheel(e:WheelEvent) {
  e.preventDefault()
  if (e.deltaX !== 0) {
    x.value += e.deltaX
  }
  if (e.deltaY !== 0) {
    x.value += e.deltaY
  }
}
// 刷新
function refresh() {
  store.refresh(route)
}
</script>