<template>
  <div>
    <div class="ml-[24px]">
      <a-checkbox
        v-model:checked="collapse"
      >
        展开/折叠
      </a-checkbox>
    </div>
    <a-divider class="mx-0 my-[8px]" />
    <a-tree
      v-if="treeDataOptions.length"
      v-bind="bindAttrs"
    >
      <template #title="data">
        {{ fieldNames ? data[fieldNames?.title] : data?.title }}
        <check-square-outlined
          v-if="props.checkStrictly && data.children.length > 0 && allChecked(data.children)"
          @click.stop="removeAllChecked(data.children, fieldNames ? data[fieldNames?.key] : data?.key)"
        />
        <minus-square-outlined
          v-else-if="props.checkStrictly && data.children.length > 0"
          @click.stop="addAllChecked(data.children, fieldNames ? data[fieldNames?.key] : data?.key)"
        />
      </template>
    </a-tree>
  </div>
</template>
<script lang="ts" setup>
import { TreeProps } from 'ant-design-vue'
import { DataNode } from 'ant-design-vue/es/tree'
import { isFunction } from 'lodash-es'
import { computed, onMounted, ref, useAttrs } from 'vue'
import { treeApiSelectProps } from './props'
import { MinusSquareOutlined, CheckSquareOutlined } from '@ant-design/icons-vue'
import type { Key } from 'ant-design-vue/es/vc-tree/interface'
import { depthToArr } from '@/utils'


// todo  组件bug fieldNames没传，内容会空白
const props = defineProps(treeApiSelectProps)
const attrs = useAttrs()
const { fieldNames } = attrs
const collapse = ref(false)
const expandKeys = ref([] as Key[])

type EmitEvents = {
  (e: 'change', ...value): void,
  (e: 'update:value', ...value): void
}
const emits = defineEmits<EmitEvents>()
const treeDataOptions = ref<DataNode[]>([])
onMounted(() => {
  fetch()
})

const checkedIds = ref<any>([])

watch(() => collapse.value, v => {
  if (v) {
    expandKeys.value = getKeys(unref(treeDataOptions))
  } else {
    expandKeys.value = []
  }
})

function getKeys(arr:any[]): Key[] {
  return depthToArr(arr, 'children').map(item => item.id)
}

if (props.value) {
  checkedIds.value = props.value
}


const bindAttrs = computed((): TreeProps => ({
  ...attrs,
  treeData: treeDataOptions.value,
  checkable: true,
  selectable: false,
  checkStrictly: props.checkStrictly, // false onCheck v的格式['v'], true onCheck v格式：v: { checked: []，: Key[];}, options：CheckInfo
  checkedKeys: unref(checkedIds),
  expandedKeys: unref(expandKeys),
  onExpand: (expandedKeys, { expanded, node }) => {
    let childrenIds = depthToArr(node?.children ?? [], 'children').map(item => item.id)
    expandKeys.value = [...expandedKeys, ...childrenIds]
  },
  // showIcon: true, // todo ture有乱码
  onCheck: (v: any, options) => {
    if (props.checkStrictly) {
      checkedIds.value = v.checked
      emits('update:value', v.checked)
    } else {
      checkedIds.value = v
      emits('update:value', v)
    }
  }
}))


function addAllChecked(arr, parentId) {
  if (!checkedIds.value.includes(parentId)) {
    checkedIds.value.push(parentId)
  }
  arr.forEach(item => {
    if (!checkedIds.value.includes(item.id)) {
      checkedIds.value.push(item.id)
    }
    if (item.children.length > 0) {
      addAllChecked(item.children, parentId)
    }
  })
  emits('update:value', checkedIds.value)
}

function removeAllChecked(arr, parentId) {
  let fieldIndex = checkedIds.value.findIndex(c => c == parentId)
  if (fieldIndex !== -1) {
    checkedIds.value.splice(fieldIndex, 1)
  }


  arr.forEach(item => {
    let index = checkedIds.value.findIndex(c => c == item.id)
    if (index !== -1) {
      checkedIds.value.splice(index, 1)
    }
    if (item.children.length > 0) {
      removeAllChecked(item.children, parentId)
    }
  })
  emits('update:value', checkedIds.value)
}


function allChecked(arr) {
  let reuslt = []
  let list = allCheckedItems(reuslt, arr)
  return list.length > 0 && list.every(item => item)
}

function allCheckedItems(result, arr) {
  let flag
  flag = arr.every(item => checkedIds.value.findIndex(c => c == item.id) > -1)
  result.push(flag)
  arr.forEach(item => {
    if (item.children.length > 0) {
      allCheckedItems(result, item.children)
    }
  })
  return result
}


async function fetch() {
  const { api, apiParams, valueToString } = props
  if (!api && !isFunction(api)) {
    return
  }
  let result = await api(apiParams)
  if (valueToString && attrs?.fieldNames !== undefined) {
    transforms(result, attrs?.fieldNames?.key)
  }
  treeDataOptions.value = result
}

function transforms(arr, valueKey) {
  arr.forEach(item => {
    item[valueKey] = item[valueKey].toString()
    if (item.children) {
      transforms(item.children, valueKey)
    } else {
      delete item.children
    }
  })
}


</script>
