<style lang="less" scoped>
.fixed-icon {
  color: #999999;

  &.active {
    color: var(--hc-primary-color);
  }

  :hover {
    color: var(--hc-primary-color);
  }
}
</style>
<template>
  <a-tooltip title="列设置">
    <a-popover
      placement="bottomLeft"
      trigger="click"
      @open-change="handleVisibleChange"
    >
      <setting-outlined style="font-size: 16px;" />
      <template #title>
        <div>
          <a-checkbox v-model:checked="state.draggable">
            数据拖拽
          </a-checkbox>
          <a-checkbox v-model:checked="state.indexable">
            显示序号
          </a-checkbox>
          <a-checkbox v-model:checked="state.selectable">
            显示多选
          </a-checkbox>
          <a-checkbox v-model:checked="state.actionable">
            操作列
          </a-checkbox>
          <!-- <a-button type="link" size="small" @click="handleReset">重置</a-button> -->
          <a-button
            v-if="tableConfigKey"
            type="link"
            size="small"
            @click="handleReset"
          >
            重置
          </a-button>
          <a-button
            v-if="tableConfigKey"
            type="link"
            size="small"
            @click="saveConfig"
          >
            保存
          </a-button>
        </div>
      </template>
      <template #content>
        <div style=" overflow: auto;max-height: 200px;">
          <a-checkbox-group
            ref="columnSortRef"
            v-model:value="state.checkedList"
            class="w-[360px]"
            @change="handelChangeCheckedList"
          >
            <div
              v-for="column in columns"
              :key="(column.dataIndex as string)"
              class="flex items-center justify-between mb-2 w-full"
            >
              <drag-outlined class="mr-2 cursor-move table-coulmn-drag-icon" />
              <a-checkbox
                class="w-full"
                :value="column.dataIndex"
              >
                {{ column.title }}
              </a-checkbox>
              <a-tooltip
                placement="bottomLeft"
                title="固定到左侧"
              >
                <vertical-right-outlined
                  :class="{ 'fixed-icon': true, active: column.fixed === true || column.fixed === 'left' }"
                  @click="handleColumnFixed(column, 'left')"
                />
              </a-tooltip>
              <a-divider type="vertical" />
              <a-tooltip
                placement="bottomLeft"
                title="固定到右侧"
              >
                <vertical-left-outlined
                  :class="{ 'fixed-icon': true, active: column.fixed === 'right' }"
                  @click="handleColumnFixed(column, 'right')"
                />
              </a-tooltip>
            </div>
          </a-checkbox-group>
        </div>
      </template>
    </a-popover>
  </a-tooltip>
</template>

<script lang="ts" setup>
import { SettingOutlined, DragOutlined, VerticalRightOutlined, VerticalLeftOutlined } from '@ant-design/icons-vue'
import { CheckboxValueType } from 'ant-design-vue/es/checkbox/interface'
import { onMounted, reactive, ref, watchEffect, unref, nextTick, watch } from 'vue'
import { useTableContext } from '../hooks/context'
import { useSortable } from '@/hooks/sortable'
import { cloneDeep, isNull, isUndefined } from 'lodash-es'
import { request } from '@/utils/request'
interface State {
  draggable: boolean;
  indexable: boolean;
  selectable: boolean;
  actionable: boolean;
  checkedList: string[];
  defaultCheckedList: string[];
  configColumns: any[]
}
let inited = false
const table = useTableContext()
const columnSortRef = ref<ComponentRef>(null)
const columns = ref<IGridTable.ColumnProps[]>([])

let originColumns = []
const state = reactive<State>({
  draggable: false,
  indexable: false,
  selectable: false,
  actionable: false,
  checkedList: [],
  defaultCheckedList: [],
  configColumns: [],
})

onMounted(async () => {
  // handleReset()
  initColumns()
  originColumns = cloneDeep(table.getColumns())
  getConfig()
})

watch(
  () => state.draggable,
  value => {
    table.setProps({ draggable: value })
  },
)

watch(
  () => state.indexable,
  value => {
    table.setProps({ indexable: value })
  },
)
watch(
  () => state.selectable,
  value => {
    table.setProps({ selectable: value })
  },
)
watch(
  () => state.actionable,
  value => {
    table.setProps({ actionable: value })
  },
)
const tableConfigKey = computed(() => unref(table.getProps()).configKey)
async function getConfig() {
  const { configKey } = unref(table.getProps())
  if (configKey) {
    const result = await request.get(`grid/tableConfig/${configKey}`)
    if (result) {
      Object.assign(state, result)
      const newColumns:any[] = []
      state.configColumns.forEach(column => {
        if (column.dataIndex && column.dataIndex !== 'action') {
          const col = columns.value.find(col =>
            col.dataIndex === column.dataIndex
            || (
              Array.isArray(col.dataIndex)
              && Array.isArray(column.dataIndex)
              && col.dataIndex.join('.') === column.dataIndex.join('.')
            ))
          if (col) {
            if (column.fixed) {
              col.fixed = column.fixed
            }
            newColumns.push(col)
          }
        }
      })

      if (newColumns.length === columns.value.length) {
        table.setColumns(newColumns)
      } else {
        console.log('表格远程配置失败，请在列设置重新保存表格配置。失败原因：1.只使用customRender，dataIndex没有赋值（解决：赋值dataIndex） 2.更改了dataIndex（解决：列设置重新保存配置）')
      }

      columns.value = table.getColumns({
        ignoreIndex: true,
        ignoreAction: true
      })
      handelChangeCheckedList(state.checkedList)
    }
  }
}
function saveConfig() {
  const { configKey } = unref(table.getProps())
  if (configKey) {
    const configColumns = [] as any
    table.getColumns().forEach((item, index) => {
      configColumns.push({
        dataIndex: item.dataIndex,
        fixed: item.fixed,
        index
      })
    })
    table.autoColumnWidth()
    request.post(`grid/tableConfig/${configKey}`, {
      ...state,
      configColumns
    })
  }
}
function handleReset() {
  const { indexable, selectable, actionable, draggable, configKey } = unref(table.getProps())
  state.draggable = draggable ?? false
  state.indexable = indexable ?? false
  state.selectable = selectable ?? false
  state.actionable = actionable ?? false
  if (configKey) {
    request.delete(`grid/tableConfig/${configKey}`)
  }
  console.log(actionable, 'filter')
  console.log(originColumns, 'originColumns')
  table.setColumns(originColumns.filter(item => item.dataIndex !== 'action'))
}

watchEffect(() => {
  if (table.getColumns().length > 0) {
    initColumns()
  }
})

function initColumns() {
  const tableColumns = table.getColumns({
    ignoreDrag: true,
    ignoreIndex: true,
    ignoreAction: true
  }) as IGridTable.ColumnProps[]
  const checkedList: any[] = tableColumns.filter(column => !column.defaultHidden).map(column => column.dataIndex)
  columns.value = tableColumns
  state.defaultCheckedList = checkedList
  state.checkedList = checkedList
}

function handelChangeCheckedList(checkedList: CheckboxValueType[]) {
  console.log('checkedList', checkedList)

  const newColumns = unref(columns).map(column => ({
    ...column,
    defaultHidden: Array.isArray(column.dataIndex) ? !checkedList.find(item => {
      if (Array.isArray(item) && item.join('.') === column?.dataIndex?.join('.')) {
        return true
      }
      return false
    }) : !checkedList.includes(column.dataIndex! as string),
  }))

  table.setColumns(newColumns)
}

function handleColumnFixed(column: IGridTable.ColumnProps, fixed: 'left' | 'right') {
  const isFixed = column.fixed === fixed ? false : fixed
  const index = columns.value.findIndex(col => col.dataIndex === column.dataIndex)
  columns.value[index].fixed = isFixed
  table.setColumns(unref(columns))
}

function handleVisibleChange() {
  if (inited) {
    return
  }
  nextTick(() => {
    const columnSortEl = unref(columnSortRef)
    if (!columnSortEl) {
      return
    }
    const el = columnSortEl.$el as any
    if (!el) {
      return
    }
    // Drag and drop sort
    const { initSortable } = useSortable(el, {
      handle: '.table-coulmn-drag-icon',
      onEnd: evt => {
        const { oldIndex, newIndex } = evt
        if ((isUndefined(oldIndex) && isNull(oldIndex)) || (isUndefined(newIndex) && isNull(newIndex)) || oldIndex === newIndex) {
          return
        }
        // Sort column
        const newColumns = unref(columns)
        if (oldIndex > newIndex) {
          newColumns.splice(newIndex, 0, newColumns[oldIndex])
          newColumns.splice(oldIndex + 1, 1)
        } else {
          newColumns.splice(newIndex + 1, 0, newColumns[oldIndex])
          newColumns.splice(oldIndex, 1)
        }

        table.setColumns(newColumns)
      },
    })
    initSortable()
    inited = true
  })
}
</script>
