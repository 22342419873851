
<template>
  <div
    @click="openHandle"
  >
    <div class="h-full flex items-center cursor-pointer">
      <setting-outlined class="text-[18px]" />
    </div>
    <a-drawer
      v-model:open="open"
      :closable="false"
      placement="right"
      :width="300"
    >
      <div class="grid gap-5">
        <a-typography-title :level="5">
          主题
        </a-typography-title>
        <div class="flex justify-between items-center">
          <a-typography-text>暗黑模式</a-typography-text>
          <a-switch
            v-model:checked="store.layout.theme"
            size="small"
            checked-value="dark"
            un-checked-value="light"
          />
        </div>
        <div class="flex justify-between items-center">
          <a-typography-text>主题色</a-typography-text>
          <ColorPicker v-model:value="store.layout.themeColor" />
        </div>
        <div class="flex justify-between items-center">
          <a-typography-text>布局背景色</a-typography-text>
          <ColorPicker v-model:value="store.layout.backgroundColor" />
        </div>
      </div>
      <a-divider />
      <div class="grid gap-5">
        <a-typography-title :level="5">
          导航模式
        </a-typography-title>
        <!-- <a-space :size="10">
          <LayoutStyle
            type="side"
            :active="store.layout.navigationMode == 'side'"
            @click="changeNavigationMode('side')"
          />
          <LayoutStyle
            type="top"
            :active="store.layout.navigationMode == 'top'"
            @click="changeNavigationMode('top')"
          />
          <LayoutStyle
            type="mix"
            :active="store.layout.navigationMode == 'mix'"
            @click="changeNavigationMode('mix')"
          />
        </a-space> -->
        <a-tooltip placement="left">
          <template #title>
            将菜单分割成 Header 和 Side
          </template>
          <div class="flex justify-between items-center">
            <a-typography-text>自动分割菜单</a-typography-text>
            <a-switch
              v-model:checked="store.layout.splitMenu"
              size="small"
              :disabled="store.layout.navigationMode != 'mix'"
            />
          </div>
        </a-tooltip>
        <div class="flex justify-between items-center">
          <a-typography-text>选项卡标签</a-typography-text>
          <a-switch
            v-model:checked="store.layout.tabTag"
            size="small"
          />
        </div>
      </div>
      <a-divider>侧边栏</a-divider>
      <div class="grid gap-5">
        <!-- <a-list-item>
          背景色
          <template #actions>
            <ColorPicker v-model:value="store.layout.sidebar.background_color" />
          </template>
        </a-list-item>
        <a-list-item>
          文字颜色
          <template #actions>
            <ColorPicker v-model:value="store.layout.sidebar.color" />
          </template>
        </a-list-item>
        <a-list-item>
          选中文字颜色
          <template #actions>
            <ColorPicker v-model:value="store.layout.sidebar.selected_color" />
          </template>
        </a-list-item> -->
        <div class="flex justify-between items-center">
          <a-typography-text>菜单主题</a-typography-text>
          <a-radio-group
            v-model:value="store.layout.sidebar.theme"
            button-style="solid"
          >
            <a-radio value="light">
              light
            </a-radio>
            <a-radio value="dark">
              dark
            </a-radio>
          </a-radio-group>
        </div>
        <div class="flex justify-between items-center">
          <a-typography-text>显示</a-typography-text>
          <a-switch
            v-model:checked="store.layout.sidebar.open"
            size="small"
          />
        </div>
        <div class="flex justify-between items-center">
          <a-typography-text>折叠</a-typography-text>
          <a-switch
            v-model:checked="store.layout.sidebar.collapsed"
            size="small"
          />
        </div>
        <div class="flex justify-between items-center">
          <div class="shrink-0 mr-6">
            <a-typography-text>宽度</a-typography-text>
          </div>
          <a-input-number
            v-model:value="store.layout.sidebar.width"
            min="100"
            max="300"
            size="small"
          />
        </div>
      </div>
      <a-divider>顶部栏</a-divider>
      <div class="grid gap-5">
        <div class="flex justify-between items-center">
          <a-typography-text>主题色</a-typography-text>
          <a-radio-group
            v-model:value="store.layout.header.theme"
            button-style="solid"
          >
            <a-radio value="theme">
              主题
            </a-radio>
            <a-radio value="white">
              白色
            </a-radio>
          </a-radio-group>
        </div>
        <!-- <a-list-item>
          背景色
          <template #actions>
            <ColorPicker v-model:value="store.layout.header.background_color" />
          </template>
        </a-list-item>
        <a-list-item>
          文字颜色
          <template #actions>
            <ColorPicker v-model:value="store.layout.header.color" />
          </template>
        </a-list-item>
        <a-list-item>
          选中文字颜色
          <template #actions>
            <ColorPicker v-model:value="store.layout.header.selected_color" />
          </template>
        </a-list-item> -->
        <a-button
          block
          @click="clear"
        >
          恢复默认
        </a-button>
      </div>
    </a-drawer>
  </div>
</template>
<script lang="ts" setup>
import ColorPicker from '@/components/form/components/common-components/color-picker/index.vue'
import { ref, watch } from 'vue'
import { useSystemStore } from '@/store/modules/system'
import { useCssVar, useDark } from '@vueuse/core'
import { SettingOutlined } from '@ant-design/icons-vue'
const store = useSystemStore()
const open = ref(false)
function openHandle() {
  open.value = true
}
const isDark = useDark({ valueLight: 'light' })
const layoutColor = changeCssVar('store.layout.backgroundColor', 'hc-layout-color')
watch(() => store.layout.theme, (type: any) => {
  layoutColor.value = type === 'dark' ? '#000000' : store.layout.backgroundColor
  isDark.value = type === 'dark'
}, { immediate: true })
// function changeNavigationMode(value: string) {
//   if (value != 'mix') {
//     store.layout.splitMenu = false
//   }
//   store.layout.navigationMode = value
//   localStorage.setItem(storageKey, JSON.stringify(store.layout))
// }
const storageKey = 'layout_setting'
watch(() => store.layout, value => {
  localStorage.setItem(storageKey, JSON.stringify(value))
}, { deep: true })


function watchCssVar() {
  // changeCssVar('store.layout.background_color', 'layout-color')
  // changeCssVar('store.layout.sidebar.background_color', 'sidebar-background-color')
  // changeCssVar('store.layout.sidebar.color', 'sidebar-color')
  // changeCssVar('store.layout.sidebar.selected_color', 'sidebar-selected-color')
  // changeCssVar('store.layout.header.background_color', 'header-background-color')
  // changeCssVar('store.layout.header.color', 'header-color')
  // changeCssVar('store.layout.header.selected_color', 'header-selected-color')
}
function changeCssVar(watchName: string, varName: string) {
  const css = useCssVar(`--${varName}`)
  watch(() => eval(watchName), (color: string) => {
    css.value = color
  }, { immediate: true })
  return css
}
function loadConfig() {
  if (localStorage.getItem(storageKey)) {
    const config: string = localStorage.getItem(storageKey) as string
    store.layout = JSON.parse(config)
  }
}

function clear() {
  localStorage.removeItem(storageKey)
  location.reload()
}

loadConfig()
</script>

