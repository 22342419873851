import { createPinia } from 'pinia'
import { App } from 'vue'
import { useAppStore } from './modules/app'
import { useUserStore } from './modules/user'
import { keepAliveStore } from './modules/keep-alive'
import { useMenuTreeStore } from './modules/nav-menu-tree'
import { useSystemStore } from './modules/system'
import { useTabStore } from './modules/tab'
const store = createPinia()

// 使用状态管理包
export function setupStore(app: App<Element>) {
  app.use(store)
}

function useUserWithoutStore() {
  return useUserStore(store)
}

export { store, useAppStore, useUserStore, keepAliveStore, useMenuTreeStore, useSystemStore, useTabStore, useUserWithoutStore }
