import { request } from '@/utils/request'

/** 员工管理接口 */
const workApi = {
  blackList: {
    list: params => request.get('/Staff/StaffUserBacklist/lists', params),
    update: params => request.post('/Staff/StaffUserBacklist/up', params)
  },

  /* 批量上传附件 */
  // batchAddFiles: params => request.post('staffuser/importContracts', params, { timeout: 500 * 1000 }),
  batchAddFiles: params => request.post('staffuser/importUserContract', params, { timeout: 500 * 1000 }),
  getNation: params => request.get('/nation/nation/lists', params),
  // 【】 获取员工列表
  getWorkList: params => request.get('Staff/Users/StaffUsers/index', params),
  //  统计入职管理数据项
  getCalcToBeEmployedList: (params?:any) => request.get('Staff/Users/StaffUsers/calcToBeEmployedList', params),
  // 统计转正管理数据项
  getCalcToBeConfirmed: (params?:any) => request.get('Staff/Users/StaffUsers/calcToBeConfirmed', params),
  // 【】 获取待入职员工列表
  toBeEmployedList: params => request.post('Staff/Users/StaffUsers/toBeEmployedList', params),
  // 【】 获取待转正员工列表
  toBeConfirmedList: params => request.post('Staff/Users/StaffUsers/toBeConfirmedList', params),
  // 转正审批
  changeUnusual: params => request.post('Staff/Users/StaffUsers/changeUnusual', params),
  // 批量转正审批
  batchChangeUnusual: params => request.post('Staff/Users/StaffUsers/batchChangeUnusual', params),
  // 异动管理列表
  getStaffUserUnusualOperateLogsList: params => request.get('Staff/StaffUserUnusualOperateLogs/index', params),
  // 【】 员工档案详细
  getWorkerDetail: id => request.get(`/Staff/Users/StaffUsers/show/${id}`),

  // 【】 批量导入员工
  batchImportWorker: params => request.post('staff/staff_users/users_import', params, {
    returnRes: true,
    disableThrottling: true,
    timeout: 500 * 1000 // 超时调整为500s
  }), // { returnRes: true }

  // 【】添加员工
  addWorker: params => request.post('/Staff/Users/StaffUsers/store', params),

  // 【】 编辑员工
  updateWorker: (id, params) => request.post(`Staff/Users/StaffUsers/update/${id}`, params),

  // 【】 删除员工
  deleteWorker: id => request.post(`Staff/Users/StaffUsers/abandonJob/${id}`),

  // 【】 员工花名册/项目员工导出
  exportWorkers: (params?: Recordable<number>) => request.get('Staff/Users/StaffUsers/export', params),

  // 【】 员工办理/编辑 离职
  handleLeaveJob: (id, params) => request.post(`Staff/Users/StaffUsers/leaveJob/${id}`, params),

  /* 办理入职 */
  handleEmployee: (id, params) => request.post(`Staff/users/StaffUsers/confirmJoinJob/${id}`, params),

  // 员工社保报表
  getStaffSocialList: params => request.post('staff/staff_social_month_reports/lists', params),

  // 员工社保报表详情
  getStaffSocialDetail: params => request.post('staff/staff_social_month_reports/get_details', params),

  /* 员工附件上传 */
  uploadFile: params => request.post('Staff/Users/StaffUsers/updateFiles', params),

  /* 员工附件上传 */
  removeFile: params => request.post('Staff/Users/StaffUsers/delFiles', params),

  /* 获取项目内在职和当月离职的员工 */
  getProjectWorkers: params => request.post('Staff/Users/StaffUsers/get_now_month_staff_users', params),

  // 员工导入批量离职
  batchImporLeavetWorker: params => request.post('Staff/Users/StaffUsers/batLeaveJob', params),

  // 员工花名册导入导员下载模板
  downloadtemplate: params => request.post('projects/projects/downloadtemplate', params),

  // 员工岗位列表
  getJobnameLists: params => request.post('projects/project_company_jobname/lists', params),

  // 员工身份证正反面上传
  uploadIdcardPic: params => request.post('Staff/Users/StaffUsers/updateIdCardPic', params),

  // 合同即将到期列表
  contractToBeExpiredList: params => request.post('Staff/StaffUsers/lists', params),

  // 离职列表
  getLeaveJobList: params => request.get('Staff/Users/StaffUsers/leaveJobList', params),
  // 离职统计
  getCalcLeaveJobList: (params?:any) => request.get('Staff/Users/StaffUsers/calcLeaveJobList', params),
}
const additionalDeduction = {

  // 导入专项附加扣除
  importAdditionalDeduction: params => request.post('staff/staff_salary_dosa/import_dosa', params, { returnRes: true })
}

// // **移动端接口前面/api保留，在utils>request.ts>Request类中有对移动端接口做处理**
// const personalApi = {

//   // 员工自主导入第一步
//   importWorkerStep1: params => request.get('api/staffUser/search', params),
//   // 员工自主导入第二步
//   importWorkerStep2: params => request.post('api/staffUser', params),

// }
const socialIncreaseAndreduceApi = {

  /* 社保增员 */
  socialIncreaseList: params => request.get('Staff/StaffSocialAdds/index', params),

  /* 批量增员成功 */
  batchIncreaseSuccess: params => request.post('Staff/StaffSocialAdds/setSocialAddSuccess', params),

  /* 社保批量减员 */
  sociaBatchReduce: params => request.post('Staff/StaffSocialAdds/setSocialReduce', params),

  /* 社保减员 */
  socialReduceList: params => request.get('Staff/StaffSocialReduces/index', params),

  /* 批量减员成功 */
  batchReduceSuccess: params => request.post('Staff/StaffSocialReduces/setSocialReduceSuccess', params),


}

export { workApi, additionalDeduction, socialIncreaseAndreduceApi }