<template>
  <div class="logo-box flex items-center">
    <div
      v-if="systemStore?.isPc"
      style="width: 170px;"
      class="flex items-center justify-center"
    >
      <img
        style="height: 37px;"
        :src="systemStore.layout.theme === 'dark' || systemStore.layout.header.theme === 'theme' ? LogoWhite : LogoColor"
      >
    </div>

    <MenuOutlined
      v-else
      class="mt-4 ml-4 text-2xl text-white"
      @click="menuVisible = true"
    />
    <a-drawer
      v-model:open="menuVisible"
      class="mobile-drawer-menu-container"
      v-bind="menuDrawerProps"
    >
      <Menu
        @clicked="menuVisible=false"
      />
    </a-drawer>
  </div>
</template>
<script lang="ts" setup>
import { useSystemStore } from '@/store'
import Menu from '../menu.vue'
import LogoWhite from '@/assets/images/logo.png'
import LogoColor from '@/assets/images/logo-color.png'

const systemStore = useSystemStore()
const menuVisible = ref(false)
const menuDrawerProps = ref({
  width: 270,
  placement: 'left',
  closable: false
})
</script>