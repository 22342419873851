export function setItem(key: string, value: any) {
  if (!value) {
    return false
  }
  localStorage.setItem(key, JSON.stringify(value))
}

export function getItem(key: string): any {
  const v = localStorage.getItem(key)
  return v ? JSON.parse(v) : ''
}

export function removeItem(key: string) {
  localStorage.removeItem(key)
}

export function setSessionItem(key: string, value: any) {
  if (!value) {
    return false
  }
  sessionStorage.setItem(key, JSON.stringify(value))
}

export function getSessionItem(key: string): any {
  const v = sessionStorage.getItem(key)
  return v ? JSON.parse(v) : ''
}

export function removeSessionItem(key: string) {
  sessionStorage.removeItem(key)
}


// 本地存储常量，比，避免各个数据的散落，直观知道有哪些 storage 存储值
export const STORAGE_KEY_TOKEN = 'token' // 本地存储 接口 token 的名
export const STORAGE_KEY_SELECTED_TOP_MENU = 'selectedTopMenu' // 选中的顶部菜单

// todo 待使用
export const USER_INFO_KEY = 'userInfo' // 本地存储用户信息的名
export const OPENID_KEY = 'openid' // 本地存储 openid 的名
export const SESSION_KEY = 'session_key' // 本地存储 session_key 的名
export const JOIN_INFO_KEY = 'joinInfo' // 本地存储 joinInfo 的名，用于公众号链接进来时的参数记录
export const APPLY_INFO_KEY = 'applyInfo' // 本地存储 applyInfo 的名，用于二礁码信息进来员工导入信息时的参数记录

export const ROUTER_CACHE_SELECTKEY = 'RouterSelectKeyCache' // 本地存储 menu菜单select Key，用于详情页刷新,激活菜单

export const HISTORY_PAGE = 'historyTab' // 本地存储 历史标签页
export const TABLE_COLLAPSES = 'tableCollapses' // 本地存储  gridTable组件的actions伸展

export const TAB_TAGS = 'tabTags' // 本地标签页

export const LAYOUT_SETTING = 'layout_setting'

export const sessionKey = {
  ANTENTDANCE_ERPORT_PROJECT_ID: 'antendance-report-project-id',
  ATTENTDANCE_STAFF_VACATION_BALANCE_PROJECT_ID: 'antendance-staff-vacation-balance-project-id'
}