import { ExtractPropTypes, PropType } from 'vue'
import type { UploadProps as AUploadProps, UploadFile } from 'ant-design-vue/es/upload'
import { ButtonType } from 'ant-design-vue/es/button'

export const uploadProps = {
  // 是否同步到erp
  syncErp: {
    type: Boolean,
    default: false
  },
  // 需要获取erp同步过来的id
  needErpId: {
    type: Boolean,
    default: false
  },
  // 文件最大大小
  maxSize: {
    type: Number,
    default: 2,
  },
  // 最大文件数
  maxNum: {
    type: Number,
    default: 1,
  },
  // 是否可以拖拽上传
  isDragger: {
    type: Boolean,
    default: false
  },
  value: {
    type: [Object, String] as PropType<string[] | string>,
    default: () => [],
    // type: [
    //   Array as PropType<string[]>,
    //   String
    // ],
    // default: () => [],
  },
  autoUpload: {
    type: Boolean,
    default: true
  },
  // 如果需要id而不是链接
  valueIsId: {
    type: Boolean,
    default: false,
  },
  // // 如果需要整个file而不是链接
  // valueIsFile: {
  //   type: Object,
  //   default: {},
  // },

  accept: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  onOriginResponseValue: {
    type: Function as PropType<(originFiles: UploadFile[]) => void>
  },
  acceptType: {
    type: String as PropType<'img' | 'file' | 'zip' | 'doc' | 'all'>,
    default: 'file',
  },

  buttonType: {
    type: String as PropType<ButtonType>,
  },
  ghost: {
    type: Boolean,
    default: false
  },

  // 上传组件提示信息
  remindInfo: {
    type: String
  },

  needFileName: {
    type: Boolean
  }
}

export type UploadProps = Partial<ExtractPropTypes<typeof uploadProps>> & AUploadProps
