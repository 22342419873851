<style scoped>
.ant-input-textarea-show-count::after {
  position: absolute;
  right: 0;
  bottom: 8px;
  font-size: 12px;
}
</style>
<template>
  <div>
    <a-textarea
      ref="aTextArea"
      v-bind="bindAttrs"
    />
    <div v-if="props.tips">
      {{ props.tips }}
    </div>
    <div v-if="props.options && props.options.length > 0">
      <i
        v-for="option in props.options"
        :key="option"
        class="bottom-0 inline-block px-2 mt-2 mr-2 text-gray-400 bg-gray-100 rounded-sm cursor-pointer select-none "
        @click="setValue(option)"
      >{{ option }}</i>
    </div>
  </div>
</template>

<script lang="ts" setup name="input-textProps">
import { InputTextAreaProps, inputTextAreaProps } from './props'
const attrs = useAttrs() as InputTextAreaProps
const props = defineProps(inputTextAreaProps)

type EmitEvents = {
  (e:'update:value', value):void
}
const emits = defineEmits<EmitEvents>()

const bindAttrs = computed(() => ({
  value: unref(props.value),
  'onUpdate:value': newValue => emits('update:value', newValue),
  ...attrs,
}))

function setValue(value: string) {
  let newStr = (attrs.value || '') + value
  if (attrs.maxlength && (attrs.maxlength < newStr.length)) {
    emits('update:value', newStr.substring(0, attrs.maxlength))
  } else {
    emits('update:value', newStr)
  }
}
</script>
