
<style scoped>
.header-menu :deep(.ant-tabs-nav) {
  margin: 6px 0 0;
}

.header-menu :deep(.ant-tabs-nav::before) {
  border: none;
}

html.light header.header-theme .header-menu :deep(.ant-tabs-tab-btn),
html.light header.header-theme .header-menu :deep(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn),
html.light header.header-theme .header-menu :deep(.ant-tabs-nav-more) {
  color: #FFFFFF;
}

html.light header.header-theme .header-menu :deep(.ant-tabs .ant-tabs-ink-bar) {
  background-color: #FFFFFF;
}

html.light .header-menu :deep(.ant-tabs-nav-more) {
  padding: 8px 10px;
  font-size: 16px;
  font-weight: bold;
}

</style>

<template>
  <div class="header-menu">
    <a-tabs
      v-if="!systemStore.isMobile && systemStore.layout.splitMenu"
      v-model:active-key="systemStore.selectedTopMenu"
      tab-position="top"
      @tabClick="tabClick"
    >
      <a-tab-pane
        v-for="item in menus"
        :key="item.path"
      >
        <template #tab>
          <div class="flex items-center">
            <basic-icon
              :name="item.meta.icon"
              class="mr-[4px]"
            />
            {{ item.meta.title }}
          </div>
        </template>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script lang="ts" setup>
import { computed, watchEffect } from 'vue'
import { useUserStore } from '@/store/modules/user'
import { useSystemStore } from '@/store/modules/system'
import { useRoute } from 'vue-router'
import { router } from '@/router'
import { arrFind, arrFindNotChildren, findParent } from '@/utils/utils'
const route = useRoute()
const store = useUserStore()
const systemStore = useSystemStore()
const menus = computed(() => store.menus.filter(item => !item.meta.hideInMenu))


watchEffect(() => {
  if (route.path === '/') {
    jump(store.menus)
  } else {
    let menu = arrFind(store.menus, 'path', route.path)
    if (menu && menu.children) {
      jump(menu.children)
    }
  }
  if (systemStore.layout.splitMenu && systemStore.homePath !== route.path) {
    let menus = findParent(store.menus, 'path', route.path)
    if (menus) {
      let menu = menus.shift()
      if (menu) {
        systemStore.selectedTopMenu = menu.meta.url
      }
    }
  } else {
    systemStore.selectedTopMenu = route.path
  }
})
function jump(menus) {
  let menu = arrFindNotChildren(menus)
  if (menu) {
    router.push(menu.meta.url)
  }
}
const tabClick = v => {
  let menu = arrFind(store.menus, 'path', v)
  if (menu && (!menu.children || menu.children.length == 0)) {
    router.push(v)
  }
}

</script>