
<style scoped lang="less">
.picker {
  position: fixed;
  z-index: 1000;
}

.color-picker {
  position: relative;
  display: inline-block;
  height: 30px;
  line-height: normal;
}

.color-picker-trigger {
  position: relative;
  display: inline-block;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  padding: 4px;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  cursor: pointer;
}

.color-picker-color {
  position: relative;
  display: block;
  border: 1px solid #999999;
  border-radius: 2px;
  height: 100%;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.color-picker-color-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.color-picker-icon {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-align: center;
  color: #FFFFFF;
  vertical-align: baseline;
}

.color-picker-empty {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  text-align: center;
  vertical-align: baseline;
}

.dark-theme {
  .color-picker-trigger {
    border: 1px dashed #434343;
  }
}
</style>
<style scoped>
.ex-color-picker :deep(.a-color-picker) {
  box-shadow: none;
}
</style>

<template>
  <a-config-provider>
    <a-popover
      v-model:open="open"
      trigger="click"
    >
      <template #content>
        <div
          ref="el"
          class="ex-color-picker"
        />
        <div style="margin-top: 10px;width: 100%;text-align: right;">
          <a-space align="end">
            <a-button
              size="small"
              @click="clear"
            >
              清空
            </a-button>
            <a-button
              size="small"
              @click="ok"
            >
              确定
            </a-button>
          </a-space>
        </div>
      </template>
      <div class="color-picker">
        <div class="color-picker-trigger">
          <span class="color-picker-color">
            <span
              class="color-picker-color-inner"
              :style="{ backgroundColor: value }"
            >
              <down-outlined
                v-if="value"
                class="color-picker-icon"
              />
              <close-outlined
                v-else
                class="color-picker-empty"
              />
            </span>

          </span>
        </div>
      </div>
    </a-popover>
  </a-config-provider>
</template>
<script setup lang="ts">
import { ref, watch, nextTick, onBeforeUnmount } from 'vue'
import { CloseOutlined, DownOutlined } from '@ant-design/icons-vue'
import * as AColorPicker from 'a-color-picker'
const props = defineProps({
  value: String,
})
const emit = defineEmits(['update:value'])
const el = ref()
let color = props.value
let selectColor = color
const open = ref(false)
let picker: any = null
let init = false
watch(open, value => {
  if (value) {
    openHandle()
  } else {
    emit('update:value', color)
  }
})
onBeforeUnmount(() => {
  if (picker) {
    picker = null
  }
})
function openHandle() {
  if (!init) {
    nextTick(() => {
      setTimeout(() => {
        picker = AColorPicker.createPicker(el.value, {
          color: props.value,
          palette: ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#000000'],
        })
        picker.on('change', (e:any) => {
          selectColor = e.color
        })
        init = true
      }, 50)
    })
  } else {
    picker.setColor(props.value)
  }
}
function clear() {
  open.value = false
  color = ''
  emit('update:value', color)
}
function ok() {
  color = selectColor
  open.value = false
}
</script>